<template>
  <div class="col-12;" style="margin-bottom: 1em">
    <Menubar :model="items" class="col-12">

    </Menubar>
    <Message severity="success">
      Opłacono zlecenie
    </Message>
  </div>
  <div class="grid">


    <div class="col-12; xl:col-3">
      <Card style="min-height: 18em;">
        <template #title>Dane</template>
        <template #content>
          <h3>#22-1321</h3>
          <Button type="button" label="NOWE" class="mr-2"/>
          <div>26.11.2022 14:50</div>
          <div>Polski - Niemiecki</div>
          <div>Przysięgłe</div>
        </template>
      </Card>
    </div>
    <div class="col-12; xl:col-3">
      <Card style="min-height: 18em;">
        <template #title>Klient</template>
        <template #content>
          <Inplace :closable="true">
            <template #display>
              {{ client.name || 'Edytuj' }}
            </template>
            <template #content>
              <InputText v-model="client.name" autoFocus/>
            </template>
          </Inplace>
          <Inplace :closable="true">
            <template #display>
              {{ client.email || 'Edytuj' }}
            </template>
            <template #content>
              <InputText v-model="client.email" autoFocus/>
            </template>
          </Inplace>
          <Inplace :closable="true">
            <template #display>
              {{ client.phone || 'Edytuj' }}
            </template>
            <template #content>
              <InputText v-model="client.phone" autoFocus/>
            </template>
          </Inplace>

        </template>
      </Card>
    </div>
    <div class="col-12; xl:col-6">
      <Card style="min-height: 18em;">
        <template #title>Załączniki</template>
        <template #content>
          <DataTable :value="attachments" responsiveLayout="scroll">
            <Column field="fileName" header="Plik"></Column>
            <Column field="wordCount" header="Słowa"></Column>
            <Column field="zzs" header="zzs"></Column>
            <Column field="price" header="Sugerowana cena"></Column>
            <Column field="fileName" headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button icon="pi pi-file-pdf" class="p-button-rounded p-button-success mr-2"
                        @click="confirmDeleteProduct(slotProps.data)"/>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </div>

    <div class="col-12">

      <Card style="">
        <template #header>

        </template>
        <template #title>Oferty</template>
        <template #subtitle></template>
        <template #content>
          <DataTable :value="offers" responsiveLayout="scroll">
            <Column field="number" header="Numer"></Column>
            <Column field="price" header="Cena netto"></Column>
            <Column field="vat" header="VAT"></Column>
            <Column field="days" header="Dni"></Column>
            <Column field="payment" header="Płatność"></Column>
            <Column field="number" headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2"
                        @click="confirmDeleteProduct(slotProps.data)"/>
                <Button icon="pi pi-trash" class="p-button-rounded  p-button-secondary" style="margin-left: 0.5em"/>
              </template>
            </Column>
          </DataTable>
        </template>
        <template #footer>
          <Button icon="pi pi-plus" label="Dodaj"/>

        </template>
      </Card>
    </div>


  </div>
</template>

<script>
export default {
  methods: {
    confirmDeleteProduct() {

    }
  },
  data() {
    return {
      dropdownItems: [
        {name: 'Option 1', code: 'Option 1'},
        {name: 'Option 2', code: 'Option 2'},
        {name: 'Option 3', code: 'Option 3'}
      ],
      attachments: [{
        "fileName": "skan1.pdf",
        "wordCount": "1503",
        "zzs": "1300",
        "price": "80 zł",
      }],
      offers: [{
        "number": "#1",
        "price": "90 zł",
        "vat": "0%",
        "days": "3 dni",
        "payment": "Przelewy24"
      }, {
        "number": "#2",
        "price": "150 zł",
        "vat": "0%",
        "days": "1 dni",
        "payment": "-"
      },
      ],
      dropdownItem: null,
      client: {
        "name": "Marek Nowak",
        "email": "mareknowak@gmail.com",
        "phone": "7391230123",
      },
      items: [
        {
          label: 'Edytuj',
          icon: 'pi pi-fw pi-pencil',
          items: [
            {
              label: 'Edytuj',
              icon: 'pi pi-fw pi-plus',
            },
            {
              label: 'Usun',
              icon: 'pi pi-fw pi-trash'
            },
            {
              separator: true
            },
            {
              label: 'Udostępnij',
              icon: 'pi pi-fw pi-external-link'
            }
          ]
        },
        {
          label: 'Wycena',
          icon: 'pi pi-fw pi-file',
          items: [
            {
              label: 'Szukaj podwykonawcy',
              icon: 'pi pi-fw pi-search'
            },
            {
              label: 'Rozpocznij faze wyceny',
              icon: 'pi pi-fw pi-align-right'
            }

          ]
        },
        {
          label: 'Oferta',
          icon: 'pi pi-fw pi-user',
          items: [
            {
              label: 'Dodaj ofertę',
              icon: 'pi pi-fw pi-user-plus',
            },
            {
              label: 'Dodaj płatność',
              icon: 'pi pi-fw pi-user-minus',
            },
          ]
        },
        {
          label: 'Dostawa',
          icon: 'pi pi-fw pi-calendar',
          items: [
            {
              label: 'Zamów kuriera',
              icon: 'pi pi-fw pi-pencil'
            },
            {
              label: 'Odbiór osobisty',
              icon: 'pi pi-fw pi-calendar-times'

            }
          ]
        },
        {
          label: 'Kontakt',
          icon: 'pi pi-fw pi-comment',
          items: [
            {
              label: 'Wyślij prośbe o feedback',
              icon: 'pi pi-fw pi-pencil'
            },
            {
              label: 'Wyślij email',
              icon: 'pi pi-fw pi-calendar-times'

            }
          ]
        },
        {
          label: 'Archiwizuj',
          icon: 'pi pi-fw pi-power-off'
        }
      ]


    }
  }
}

</script>
